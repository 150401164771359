import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { cancelSubscription, changePassword, createCheckoutSession, deleteAccount, getUser } from "../api/thunks/users";
import { ToolbarComponent } from "./toolbar-component";
import { Conversation } from "../api/models/Conversation";
import { setNotification } from "../store/notifications-reducer";

export const ProfileComponent = ({
    convos,
    convo,
    handleCreateConversation,
    handleDeleteConversation,
    supportedTickers
}: {
    convos: Conversation[],
    convo: Conversation | undefined,
    handleCreateConversation: (ticker: string) => Promise<void>,
    handleDeleteConversation: (id: string) => Promise<void>,
    supportedTickers: string[]
}) => {
    document.title = "Profile | PocketQuant";
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((state) => state.user.credentials);
    const user = useAppSelector((state) => state.user.user);
    const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);

    const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    useEffect(() => {
        if (!credentials) {
            sessionStorage.clear();
            navigate("/login");
            return;
        }
    }, [credentials]);

    useEffect(() => {
        dispatch(getUser());
        const params = new URLSearchParams(window.location.search);
        if (params.get('payment_success') === 'true') {
            console.log("sending purchase conversion event")
            gtag('event', 'conversion', {
                'send_to': 'AW-16667259928/KabTCJq7hpQaEJjoyIs-',
                'value': 20.0,
                'currency': 'USD',
                'transaction_id': user?.id
            });
            console.log('done sending purchase conversion event');
            dispatch(setNotification({ message: 'Payment successful', type: 'success' }));
        }

        if (params.get('payment_success') === 'false') {
            dispatch(setNotification({ message: 'Payment failed', type: 'error' }));
        }
    }, []);

    const handleCreateSubscription = async () => {
        if (process.env.REACT_APP_PQ_PREMIUM_PRICE_ID === undefined) {
            dispatch(setNotification({ message: 'Failed to create checkout session, price id not defined', type: 'error' }));
            return;
        }
        const res = await dispatch(createCheckoutSession({ productCode: process.env.REACT_APP_PQ_PREMIUM_PRICE_ID }));
        if (res.meta.requestStatus === 'fulfilled') {
            window.location.href = (res.payload as any).redirectUrl;
        } else {
            dispatch(setNotification({ message: 'Failed to create checkout session', type: 'error' }));
        }
    }

    const handleChangePassword = async () => {
        if (!isChangingPassword) {
            setIsChangingPassword(true);
            return;
        }

        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        const res = await dispatch(changePassword({ currentPassword: currentPassword, newPassword: newPassword }));

        if (res.meta.requestStatus === 'fulfilled') {
            alert('Password changed successfully');
            setIsChangingPassword(false);
        } else {
            alert('Failed to change password');
        }
    }

    const handleDeleteAccount = async () => {
        if (user?.hasActiveSubscription === true) {
            alert('Please cancel your subscription before deleting your account');
            return;
        }

        const deleteConfirmation = window.confirm('Are you sure you want to delete your account? This action cannot be undone.');
        if (!deleteConfirmation) {
            return;
        }


        const res = await dispatch(deleteAccount());
        if (res.meta.requestStatus !== 'fulfilled') {
            alert('Failed to delete account. Please try again or email us.');
            return;
        }
        alert("We are sorry to see you go. Your account has been deleted.");
        sessionStorage.clear();
        navigate('/');
    }

    return (
        <>
            <CancelSubscriptionModal isOpen={unsubscribeModalOpen} setIsOpen={setUnsubscribeModalOpen} />

            <div className="grid grid-rows-[1fr_auto] h-screen overflow-hidden">


                <div className="grid grid-cols-1 gap-4 p-4 overflow-hidden">

                    <div className="min-h-screen bg-gradient-to-r flex justify-center items-center py-10 px-4">
                        <div className="w-full max-w-5xl bg-white rounded-lg shadow-lg p-8 grid grid-cols-1 md:grid-cols-2 gap-8">
                            {/* Profile Section */}
                            <div className="flex flex-col space-y-4">
                                <h2 className="text-2xl font-bold text-gray-800">Profile</h2>
                                <div>
                                    <p><span className="font-semibold text-gray-800">Name:</span> {user?.name}</p>
                                    <p><span className="font-semibold text-gray-800">Email:</span> {user?.email}</p>
                                    <p><span className="font-semibold text-grey-800">Subscription:</span> {user?.hasActiveSubscription ? <b className="text-green-800">Active</b> : 'Inactive'}</p>
                                </div>
                                {isChangingPassword &&
                                    <div className="flex flex-col space-y-4">
                                        <input onChange={(e) => setCurrentPassword(e.target.value)} type="password" placeholder="Current Password" className="py-2 px-4 bg-gray-100 rounded-lg" />
                                        <input onChange={(e) => setNewPassword(e.target.value)} type="password" placeholder="New Password" className="py-2 px-4 bg-gray-100 rounded-lg" />
                                        <input onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder="Confirm Password" className="py-2 px-4 bg-gray-100 rounded-lg" />
                                    </div>
                                }
                                <button onClick={() => handleChangePassword()} className="w-1/2 mt-6 py-2 px-4 bg-orange-600 text-white font-bold rounded-lg hover:bg-orange-700 transition duration-300">
                                    {isChangingPassword ? 'Save Changes' : 'Change Password'}
                                </button>
                                <p onClick={() => handleDeleteAccount()} className="text-gray-400 text-sm mt-2 hover:cursor-pointer">
                                    <u>Delete Account</u>
                                </p>
                            </div>

                            {/* Billing Section */}
                            <div className="bg-gray-50 p-6 rounded-lg flex flex-col justify-between">
                                <div>
                                    <h2 className="text-2xl font-bold mb-2">Monthly subscription</h2>
                                    <p className="text-gray-600 mb-4">
                                        Get access to all the premium PocketQuant features.
                                    </p>
                                    <h3 className="text-lg font-semibold text-orange-600 mb-4">What’s included</h3>
                                    <ul className="grid grid-cols-2 gap-2">
                                        <li className="flex"><span className="text-orange-500 mr-2">✓</span> 100 monthly copilot prompts</li>
                                        <li className="flex"><span className="text-orange-500 mr-2">✓</span> Earnings reports since 2009</li>
                                        <li className="flex"><span className="text-orange-500 mr-2">✓</span> Transcripts since 2009</li>
                                    </ul>
                                </div>
                                <div className="text-right mt-6">
                                    <p className="text-4xl font-bold text-gray-900">$10 <span className="text-sm text-gray-500">USD/month</span></p>
                                    <button onClick={handleCreateSubscription} disabled={user?.hasActiveSubscription === true} className={`mt-4 py-2 px-4 bg-orange-600 text-white font-bold rounded-lg transition duration-300 ${user?.hasActiveSubscription === true ? 'opacity-75' : 'hover:bg-orange-700 hover:cursor-pointer'}`}>
                                        Buy now
                                    </button>
                                    {user?.hasActiveSubscription === true &&
                                        <p onClick={() => {
                                            setUnsubscribeModalOpen(true);
                                            // handleCancelSubscription('too_expensive', 'too_expensive')
                                        }} className="text-gray-400 text-sm mt-2 hover:cursor-pointer">
                                            <u>Cancel subscription</u>
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToolbarComponent convo={convo} convos={convos} handleConversationClick={(id: string) => {
                    const queryParams = new URLSearchParams(window.location.search);
                    queryParams.set('conversationId', id);
                    navigate('/dashboard' + '?' + queryParams.toString());
                    navigate(0);
                }} handleCreateConversation={handleCreateConversation} handleDeleteConversation={handleDeleteConversation} supportedTickers={supportedTickers} />

            </div>
        </>
    )
}

const CancelSubscriptionModal = ({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean,
    setIsOpen: (val: boolean) => void
}) => {
    const [feedback, setSelectedFeedback] = useState('');
    const [additionalComments, setAdditionalComments] = useState('');
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user.user);

    const handleFeedbackChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedFeedback(e.currentTarget.value);
    };

    const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAdditionalComments(e.target.value);
    };

    const handleCancelSubscription = async (e: any) => {
        e.preventDefault();
        if (!user?.hasActiveSubscription) {
            alert('No active subscription to cancel');
            return;
        }

        const res = await dispatch(cancelSubscription({ reason: additionalComments, feedback: feedback }));
        dispatch(getUser());
        if (res.meta.requestStatus === 'fulfilled') {
            alert('Subscription cancelled successfully');
        } else {
            alert('Failed to cancel subscription');
        }
        setIsOpen(false);
    }


    return (
        <div className={`${!isOpen ? 'hidden' : ''} fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center`}>
            <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 p-6">
                <h2 className="text-2xl font-bold mb-4 text-gray-800">Cancel Subscription</h2>
                <form onSubmit={async (e) => {
                    await handleCancelSubscription(e);
                }}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2" htmlFor="reason">
                            Why are you canceling?
                        </label>
                        <select
                            id="reason"
                            value={feedback}
                            onChange={handleFeedbackChange}
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
                            required
                        >
                            <option value="" disabled>Select a reason</option>
                            <option value="too_expensive">Too expensive</option>
                            <option value="missing_features">Missing features</option>
                            <option value="switched_service">Switched to another service</option>
                            <option value="unused">Not using the service</option>
                            <option value="customer_service">Issues with customer service</option>
                            <option value="too_complex">Too complex</option>
                            <option value="low_quality">Low quality</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2" htmlFor="comments">
                            Additional comments
                        </label>
                        <textarea
                            id="comments"
                            value={additionalComments}
                            onChange={handleReasonChange}
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
                            rows={4}
                        ></textarea>
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={() => setIsOpen(false)}
                            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-orange-700 transition duration-300"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
