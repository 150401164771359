import { useNavigate } from "react-router-dom";
import { createUser, signIn } from "../api/thunks/users";
import { useAppDispatch } from "../store/hooks";
import { useState } from "react";
import GoogleLoginButton from "./google-login-button";
import { v4 } from "uuid";
import { setNotification } from "../store/notifications-reducer";
import NotificationPopup from "./notification-popup-component";

export const SignUpComponent = () => {
    document.title = "Sign Up | PocketQuant";
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // const name = (e.currentTarget.elements[0] as HTMLInputElement).value;
        const email = (e.currentTarget.elements[0] as HTMLInputElement).value;
        const password = (e.currentTarget.elements[1] as HTMLInputElement).value;
        const confirm_password = (e.currentTarget.elements[2] as HTMLInputElement).value;
        if (password !== confirm_password) {
            console.log('passwords do not match');
            dispatch(setNotification({
                message: 'Passwords do not match',
                type: 'error'
            }));
            return;
        }

        const passwordValidation = validatePassword(password);
        if (!passwordValidation.valid) {
            dispatch(setNotification({
                message: passwordValidation.message,
                type: 'error'
            }));
            return;
        }

        const res = await dispatch(createUser({ name: email, email, password }));

        if (res.meta.requestStatus !== 'fulfilled') {
            dispatch(setNotification({
                message: 'Failed to sign up',
                type: 'error'
            }));
            return;
        }

        signIn(dispatch, { email, password }).then(async (res) => {
            dispatch(setNotification({
                message: 'Sign up succeeded. Redirecting to sign in page...',
                type: 'success'
            }));
            navigate('/login');
        }).catch((err) => {
            dispatch(setNotification({
                message: 'Failed to sign in',
                type: 'error'
            }));
        })
    }

    function validatePassword(password: string) {
        // Check for minimum length of 8 characters
        if (password.length < 6) {
            return { valid: false, message: 'Password must be at least 6 characters long.' };
        }

        // Check for at least 1 number
        // if (!/\d/.test(password)) {
        //     return { valid: false, message: 'Password must contain at least one number.' };
        // }

        // Check for at least 1 special character
        // if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        //     return { valid: false, message: 'Password must contain at least one special character.' };
        // }

        // Check for at least 1 uppercase letter
        // if (!/[A-Z]/.test(password)) {
        //     return { valid: false, message: 'Password must contain at least one uppercase letter.' };
        // }

        // Check for at least 1 lowercase letter
        // if (!/[a-z]/.test(password)) {
        //     return { valid: false, message: 'Password must contain at least one lowercase letter.' };
        // }

        return { valid: true, message: 'Password is valid.' };
    }


    return (
        <>
            <NotificationPopup />
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <a href="/">
                        <img
                            className="mx-auto h-10 w-auto"
                            src="pq-icon-512.png"
                            alt="PocketQuant Logo"
                        /></a>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Create your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        {/* <div>
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div> */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>

                            <div className="flex items-center justify-between">
                                <label htmlFor="confirm_password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Confirm Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="confirm_password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* <div>
                            <p className="text-sm text-gray-900">
                                Password policy:
                                <ul className="list-disc pl-5">
                                    <li>
                                        at least 8 characters long
                                    </li>
                                    <li>
                                        one number
                                    </li>
                                    <li>
                                        one special character
                                    </li>
                                    <li>
                                        one uppercase letter
                                    </li>
                                    <li>
                                        one lowercase letter
                                    </li>
                                </ul>
                            </p>
                        </div> */}

                        <div>
                            <div className="flex items-center">
                                <input
                                    id="terms"
                                    name="terms"
                                    type="checkbox"
                                    required
                                    className="h-4 w-4 text-orange-400 border-gray-300 focus:ring-orange-500"
                                />
                                <label htmlFor="terms" className="ml-2 block text-sm leading-5 text-gray-900">
                                    I agree to the <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/terms-of-service');
                                    }} className="font-medium text-orange-400 hover:text-orange-500">terms of service</a> and <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/privacy-policy');
                                    }} className="font-medium text-orange-400 hover:text-orange-500">privacy policy</a>
                                </label>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full mb-2 justify-center rounded-md bg-orange-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                Sign Up
                            </button>
                            <GoogleLoginButton label='Sign up with Google' additionalStyles={{
                                width: "100%",
                            }} onClick={(e: any) => {
                                e.preventDefault();
                                const query = new URLSearchParams(window.location.search);
                                console.log("query", query.toString());
                                fetch(`${process.env.REACT_APP_API_URL}/v1/users/google?${query.toString()}`, {
                                    method: "POST",
                                    headers: {
                                        'X-Request-ID': v4(),
                                        "Content-Type": "application/json",
                                    },
                                })
                                    .then((res) => res.json())
                                    .then((res) => {
                                        window.location.href = res.url;
                                    });
                            }} />
                        </div>
                        <p className="mt-10 text-center text-sm text-gray-500">
                            Already a member?{' '}
                            <a href="#" onClick={() => {
                                window.location.href = "/login?" + query.toString();
                            }} className="font-semibold leading-6 text-orange-600 hover:text-orange-500">
                                Login
                            </a>
                        </p>
                    </form>
                </div>
            </div>
        </>
    )
}