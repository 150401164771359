import { useState, useRef, useEffect } from 'react';

export const FunkyHueCircleThing = () => {
    const [intensity, setIntensity] = useState(0);
    const circleRef = useRef<any>(null);

    const handleMouseMove = (e: any) => {
        if (!circleRef.current) return;

        const rect = circleRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        // Calculate distance from center
        const distance = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2);

        // Normalize distance to a 0-1 range (adjust maxDistance as needed)
        const maxDistance = Math.max(centerX, centerY); // Use the radius as max distance
        const normalizedDistance = Math.min(1, distance / maxDistance); // Clamp to 0-1

        // Invert the normalized distance so closer = stronger shadow
        const invertedDistance = 1 - normalizedDistance;

        // Scale intensity to desired range (e.g., 0.2 to 0.8 opacity)
        const minOpacity = 0.2;
        const maxOpacity = 0.8;
        const opacity = minOpacity + (maxOpacity - minOpacity) * invertedDistance;

        setIntensity(opacity);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="hero-container">
            <div className="hero-content">
                <h1>Dedicated AI assistants for every company.</h1>
                <p>
                    Turn data into a discussion with AI that's 3x more accurate than
                    OpenAI's GPT-4o. Investigate companies in depth using accurate data
                    sourced from SEC filings.
                </p>
                <a
                    href="/signup"
                    className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                    Start chatting for free
                </a>
                <span className="no-cc-text">No credit card required.</span>
            </div>
            <div
                className="circle"
                ref={circleRef}
                style={{
                    boxShadow: `0 0 10px 10px rgba(255, 165, 0, ${intensity})`,
                }}
            ></div>
        </div>
    );
};