import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import { EarningsReportComponent } from "./earnings-report-component"
import { LlmConvoComponent } from "./llm-convo-component"
import { TranscriptComponent } from "./transcript-component"
import { useLocation, useNavigate } from "react-router-dom"
import { createMessage } from "../api/thunks/conversations"
import { Conversation } from "../api/models/Conversation"
import { Message } from "../api/models/Message"
import { setNotification } from "../store/notifications-reducer"
import { Transcript } from "../api/models/Transcript"
import { EarningsReport } from "../api/models/EarningsReport"
import { getEarningsTranscript } from "../api/thunks/transcripts"
import { getEarningsDates, getEarningsReport, getQuestionSuggestions } from "../api/thunks/financials"
import { EarningsDate } from "../api/models/EarningsDate"
import { ImgModalState } from "./image-modal"
import { ToolbarComponent } from "./toolbar-component"

export const DashboardComponent = ({
    convos,
    convo,
    setConvo,
    setImgModalState,
    openCodeModal,
    supportedTickers,
    handleCreateConversation,
    handleDeleteConversation,
    handleCreateArtifact,
    setLastMessageSentTime
}: {
    supportedTickers: string[];
    convos: Conversation[],
    convo: Conversation | undefined;
    setConvo: (convo: Conversation) => void;
    setImgModalState: (state: ImgModalState) => void;
    openCodeModal: (code: string) => void;
    handleCreateConversation: (ticker: string) => Promise<void>;
    handleDeleteConversation: (id: string) => Promise<void>;
    handleCreateArtifact: (type: 'code' | 'image', data: string) => Promise<void>;
    setLastMessageSentTime: (time: Date) => void;
}) => {
    document.title = "Dashboard | PocketQuant";
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((state) => state.user.credentials);

    const [transcript, setTranscript] = useState<Transcript | undefined>();
    const [earningsReport, setEarningsReport] = useState<EarningsReport | undefined>();
    const [message, setMessage] = useState('');
    const [earningsFiscalDateEnding, setEarningsFiscalDateEnding] = useState('');
    const [transcriptFiscalDateEnding, setTranscriptFiscalDateEnding] = useState('');

    const [transcriptEarningsDates, setTranscriptEarningsDates] = useState<EarningsDate[]>([]);
    const [earningsReportsDates, setEarningsReportsDates] = useState<EarningsDate[]>([]);

    const [questionSuggestions, setQuestionSuggestions] = useState<string[]>([]);
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleConversationClick = (id: string) => {
        navigate('/dashboard?conversationId=' + id);
        navigate(0);
    };

    const sendMessage = async (): Promise<boolean> => {
        if (!credentials) {
            dispatch(setNotification({
                message: 'Please login to send a message',
                type: 'error'
            }));
            return false;
        }
        if (convo === undefined) {
            dispatch(setNotification({
                message: 'Please select or create a conversation',
                type: 'error'
            }));
            return false;
        }
        if (convo && message.length > 0) {
            const msgId = await createMessage(convo.id, {
                content: message,
                frontendContext: {
                    earningsFiscalDateEnding: earningsFiscalDateEnding,
                    transcriptFiscalDateEnding: transcriptFiscalDateEnding
                },
            }, credentials.accessToken, dispatch);

            if (!msgId) {
                return false;
            }

            const convoDeepCopy = JSON.parse(JSON.stringify(convo)) as Conversation;
            convoDeepCopy.messages?.push({
                id: msgId,
                role: Message.RoleEnum.User,
                content: [{ text: message }]
            });
            setConvo(convoDeepCopy);
            setLastMessageSentTime(new Date())
        }

        return true;
    };

    async function getCitationTextDetails(conversationId: string, messageId: string, fileId: string) {
        const msg = convo?.messages?.find(x => x.id === messageId);
        if (!msg) {
            return 'error getting citation text';
        }

        const allFileSearches = msg.runDetails.flatMap((detail: any) =>
            detail.type === "tool_calls"
                ? detail.tool_calls.filter((call: any) => call.type === "file_search")
                : []
        );

        let searchesForThisFile = [];

        for (let i = 0; i < allFileSearches.length; i++) {
            for (let j = 0; j < allFileSearches[i].file_search.results.length; j++) {
                if (allFileSearches[i].file_search.results[j].file_id === fileId) {
                    searchesForThisFile.push(allFileSearches[i].file_search.results[j]);
                }
            }
        }

        let concatenatedText = "";
        for (let i = 0; i < searchesForThisFile.length; i++) {
            concatenatedText += searchesForThisFile[i].content[0].text;
        }

        return concatenatedText;
    }

    useEffect(() => {
        if (!credentials) {
            return;
        }

        const query = new URLSearchParams(location.search);
        const queryEarningsFiscalDateEnding = query.get('earningsFiscalDateEnding');
        const queryTranscriptFiscalDateEnding = query.get('transcriptFiscalDateEnding');
        let setTranscriptFiscalDateEndingFromQuery = false;
        let setEarningsFiscalDateEndingFromQuery = false;
        if (queryEarningsFiscalDateEnding !== null) {
            setEarningsFiscalDateEnding(queryEarningsFiscalDateEnding);
            setEarningsFiscalDateEndingFromQuery = true;
        }
        if (queryTranscriptFiscalDateEnding !== null) {
            setTranscriptFiscalDateEnding(queryTranscriptFiscalDateEnding);
            setTranscriptFiscalDateEndingFromQuery = true;
        }

        if (!convo) return;

        getEarningsDates({ ticker: convo.ticker, entityType: 'transcript' }, credentials.accessToken).then((data) => {
            const mostRecentWithAccess = data.dates.find((date) => date.userHasAccess);
            if (!mostRecentWithAccess) {
                return
            }
            setTranscriptEarningsDates(data.dates);
            if (!setTranscriptFiscalDateEndingFromQuery) {
                setTranscriptFiscalDateEnding(mostRecentWithAccess!.date);
            }
        }).catch((e) => {
            dispatch(setNotification({
                message: 'Failed to get transcript earnings dates',
                type: 'error'
            }));
        });

        getEarningsDates({ ticker: convo.ticker, entityType: 'earnings' }, credentials.accessToken).then((data) => {
            const mostRecentWithAccess = data.dates.find((date) => date.userHasAccess);
            if (!mostRecentWithAccess) {
                return
            }
            setEarningsReportsDates(data.dates);
            if (!setEarningsFiscalDateEndingFromQuery) {
                setEarningsFiscalDateEnding(mostRecentWithAccess!.date);
            }
        }).catch((e) => {
            console.log(e);
            dispatch(setNotification({
                message: 'Failed to get earnings dates',
                type: 'error'
            }));
        });
    }, [credentials, location.search, convo]);

    useEffect(() => {
        if (!credentials) {
            return;
        }

        if (convo && transcriptFiscalDateEnding) {
            getEarningsTranscript(convo.ticker, transcriptFiscalDateEnding, credentials.accessToken, dispatch).then((data) => {
                setTranscript(data);
            }).catch((e) => {
                dispatch(setNotification({
                    message: 'Failed to get transcript',
                    type: 'error'
                }));
            });
        }
    }, [convo, credentials, transcriptFiscalDateEnding])

    useEffect(() => {
        if (!credentials) {
            return;
        }
        if (convo && earningsFiscalDateEnding) {
            getEarningsReport(convo.ticker, earningsFiscalDateEnding, credentials.accessToken, dispatch).then((data) => {
                setEarningsReport(data);
            }).catch((e) => {
                dispatch(setNotification({
                    message: 'Failed to get earnings report',
                    type: 'error'
                }));
            });
        }
    }, [convo, credentials, earningsFiscalDateEnding]);

    useEffect(()=>{
        if (!convo || !credentials) return;
        getQuestionSuggestions(convo.ticker, credentials.accessToken).then((data) => {
            console.log('got suggestions', data);
            setQuestionSuggestions(data);
        }).catch((e) => {
            console.error("Failed to get question suggestions", e);
        });
    }, [convo])

    return (
        <div className="grid grid-rows-[1fr_auto] h-screen overflow-hidden">
            {/* Top Row */}
            <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4 p-4 overflow-hidden">
                {/* Earnings Statement */}
                <div className="bg-white/[0.2] hidden md:block rounded-lg shadow p-4 overflow-y-auto">
                    <EarningsReportComponent setCurrentFiscalDateEnding={(date: string) => {
                        setEarningsFiscalDateEnding(date);
                        // set query param
                        const query = new URLSearchParams(location.search);
                        query.set('earningsFiscalDateEnding', date);
                        window.history.replaceState({}, '', `${location.pathname}?${query.toString()}`);
                    }} earnings={earningsReport} currentFiscalDateEnding={earningsFiscalDateEnding} earningsDates={earningsReportsDates} />
                </div>
                {/* LLM Chat */}
                <div className="bg-white/[0.2] rounded-lg shadow p-4 overflow-y-auto">
                    <LlmConvoComponent questionSuggestions={questionSuggestions} createArtifact={handleCreateArtifact} openCodeModal={openCodeModal} setImgModalState={setImgModalState} getCitationTextDetails={getCitationTextDetails} convo={convo} message={message} sendMessage={sendMessage} setMessage={setMessage} />
                </div>
                {/* Transcript */}
                <div className="bg-white/[0.2] hidden md:block rounded-lg shadow p-4 overflow-y-auto">
                    <TranscriptComponent ticker={convo?.ticker || 'unknown'} transcript={transcript} currentFiscalDateEnding={transcriptFiscalDateEnding} setCurrentFiscalDateEnding={(date: string) => {
                        setTranscriptFiscalDateEnding(date);
                        // set query param
                        const query = new URLSearchParams(location.search);
                        query.set('transcriptFiscalDateEnding', date);
                        window.history.replaceState({}, '', `${location.pathname}?${query.toString()}`);
                    }} earningsDates={transcriptEarningsDates} />
                </div>
            </div>

            {/* Bottom Row (Toolbar) */}
            <ToolbarComponent convo={convo} convos={convos} handleConversationClick={handleConversationClick} handleCreateConversation={handleCreateConversation} supportedTickers={supportedTickers} handleDeleteConversation={handleDeleteConversation} />
        </div>
    )
}